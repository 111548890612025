/* =======================================================================
* Template Name: VERSAT
* Author: The Creo
* Version: 1.0
* Design and Developed by: The Creo Team
======================================================================= */
/*
====================================
[ CSS TABLE CONTENT ]
------------------------------------
    1.0 - General Style
            - Link Style
            - Heading (Typography)
            - Background Color
            - Pre Loader
    2.0 - Home Page Style
    3.0 - Service Section
    4.0 - Portfolio Section
    5.0 - Portfolio-Project-Details
    6.0 - About Us
    7.0 - Skill Bar
    8.0 - Testimonial Section
    9.0 - Client Section
    10.0 - Team Section
    11.0 - Package Section
    12.0 - Fun Factors
    13.0 - Blog Section
    14.0 - Blog List Page
    15.0 - Single Blog Page
    16.0 - Contact Section
    17.0 - Google Map
    18.0 - Responsive 
    
-------------------------------------
[ END CSS TABLE CONTENT ]
=====================================
*/
/* --------------------------------------
=========================================
GENERAL STYLES
=========================================
-----------------------------------------*/
html {
    font-size: 100%;
}
body {
    height: 100%;
    background: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    overflow-x: hidden !important;
    margin: auto !important;
}
hr{
  
}
strong{
  font-weight: 700;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: left;
}
/* solid color background*/
.white-bg {
  background: #fff;
}
/* Internet Explorer 10 in Windows 8 and Windows Phone 8 Bug fix */ 
@-webkit-viewport {
    width: device-width;
}
@-moz-viewport {
    width: device-width;
}
@-ms-viewport {
    width: device-width;
}
@-o-viewport {
    width: device-width;
}
@viewport {
    width: device-width;
}
/* Link style
/* ------------------------------ */
a {
   color: #000;
    -webkit-transition: all ease 0.25s;
            transition: all ease 0.25s;
}
a:hover {
    text-decoration: none;
    opacity: 0.9;
}
a:active {
    text-decoration: none;
    opacity: 0.7;
}
a:visited {
    text-decoration: none;
    opacity: 0.7;
}
a:focus{
  text-decoration: none;
  opacity: 0.7;
}
.btn{
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 100%;
    margin: 10px 0;
    outline: 0 none;
    padding: 10px 20px;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    border: 2px solid #111;
    border-radius: 0px;
    background: transparent;
}
.btn:hover{
  color: #fff;
}
.btn-price{
  margin-top: 10px;
  border: 1px solid;
  border-color: #949494;
  border-left: none;
  border-right: none;
  border-bottom: none;
  width: 100%;
  margin-bottom: 0px; 
}
.btn-home{
  color: #fff;
  border: 2px solid #fff;
}
.btn-home:hover{
  color: #000;
}
.btn-home:hover a{
  color: #000;
}
.btn-home a{
  color: #fff;
}
.btn-home a:hover{
  color: #000;
}
.btn-home:hover a:visited{
  color: #000;
}
.btn-home a:visited{
  color: #fff;
}
.btn:focus,
.btn:active {
    outline: inherit;
}
textarea:focus, input:focus {
    color: #ffffff;
}
/* Scroll Down */
 .scroll-btn {
  display: block;
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 200;
}
.scroll-btn > * {
  display: inline-block;
  line-height: 10px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  color: #000;
  letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
  color: #000;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.scroll-btn .mouse {
  position: relative;
  display: block;
  width: 25px;
  height: 10px;
  margin: 0 auto 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.scroll-btn .mouse > * {
  position: absolute;
  display: block;
  /*top: 30%;*/
  left: -10%;
  font-size: 32px;
}
/* Other fixes*/   
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
            box-sizing: border-box;
}
ul,
ol {
    padding-left: 0 !important;
}
li {
    list-style: none;
}
.nopadding{
  padding: 0px;
}
.numbers{
  font-family: 'Advent Pro', sans-serif;
  font-weight: 500;
}
/*---------------------------------------
   HEADING             
-----------------------------------------*/
h1 {
    font-size: 3.75rem;
    line-height: 4.688rem;
}
h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 42px;
    letter-spacing: 1px;
    line-height: 4.063rem;
    text-transform: capitalize;
}
h3 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 300;
}
h4 {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
}
h5 {
    font-size: 16px;
    font-weight: 300;
}
p { color: #6d747c;
    font-size: 16px;
    font-weight: 300;
}
/*---------------------------------------
   TEXTS AND BG               
-----------------------------------------*/
.section-title {
    color: #000;
}
.text-dark{
  color: #000;
}
.text-white{
  color: #FFF;
}
.text-dark-blue{
  color: #004dff;
}
.bg-white{
  background: #fff;
}
.section-top{
  padding-top: 60px;
}
.section-header {
    position: relative;
    top: 50px;
}
.section-header h2 {
    margin-bottom: 20px;
}
.section-header .section-description {
    display: inline-block;
    position: relative;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
}
.colored-line {
    margin: auto;
    z-index: 1;
    width: 165px;
    height: 1px;
}
.white-line {
    margin: auto;
    z-index: 10;
    width: 50px;
    height: 2px;
    color: #000;
}
.vertical-line {
    margin-left: 43px;
    z-index: 1;
    width: 1px;
    height: 35px;
    background: #000;
    text-align: left;
}
header {
    -webkit-background-size: cover;
            background-size: cover;
}
header .color-overlay {
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
}
/*Water Mark*/
.water-mark{
  position: relative;
}
.water-mark .main-title {
  position: relative;
  top: -25px;
  z-index: 100;
  font-family: 'Raleway', sans-serif;
  color: rgba(0, 0, 0, 0.1);
  font-size: 100px;
  font-weight: 800;
  left: 0;
  text-transform: none;
}
.water-mark-70 .main-title{
  top: -70px;
}

@media (max-width: 320px) {
  .water-mark .main-title {
    font-size: 93px;
  }
}
/*Over-ride page-title */
.over-page-title h5 {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 5px;
  font-weight: 700;
}
.over-page-title .page-title h1 {
  width: 1170px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 5px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}
/* PRE LOADER
/* ------------------------------ */
.no-js .preloader{
  display: none;
}
.preloader {
    position: fixed;
    background-color: #fff;
    z-index: 99999;
    height: 100%;
    width: 100%;
    overflow: hidden !important;
}
.status {
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
            background-size: cover;
}
.ip-header {
  position: fixed;
  top: 0;
  z-index: 100;
  min-height: 480px;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
}
.ip-header h1 {
  margin: 0;
}
.ip-loader {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  cursor: default;
  pointer-events: none;
}
.ip-loader {
  bottom: 50%;
}
.ip-header .ip-inner {
  display: block;
  margin: 0 auto;
}
.ip-header .ip-loader svg path {
  fill: none;
  stroke-width: 6;
}
.ip-header .ip-loader svg path.ip-loader-circlebg {
  stroke: #ddd;
}
.ip-header .ip-loader svg path.ip-loader-circle {
  -webkit-transition: stroke-dashoffset 0.2s;
  transition: stroke-dashoffset 0.2s;
}
/* Animations */
/* Initial animation of header elements */
.loading .ip-loader {
  opacity: 1;
  -webkit-animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
  animation: animInitialHeader 1s cubic-bezier(0.7,0,0.3,1) both;
}
.loading .ip-loader {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
@-webkit-keyframes animInitialHeader {
  from { opacity: 0; -webkit-transform: translate3d(0,800px,0); }
}
@keyframes animInitialHeader {
  from { opacity: 0; -webkit-transform: translate3d(0,800px,0); transform: translate3d(0,800px,0); }
}
/* Header elements when loading finishes */
.loaded .ip-loader {
  opacity: 1;
}
@-webkit-keyframes animLoadedLogo {
  to { -webkit-transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); }
}
@keyframes animLoadedLogo {
  to { -webkit-transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1); }
}
.loaded .ip-loader {
  -webkit-animation: animLoadedLoader 0.5s cubic-bezier(0.7,0,0.3,1) forwards;
  animation: animLoadedLoader 0.5s cubic-bezier(0.7,0,0.3,1) forwards;
}
@-webkit-keyframes animLoadedLoader {
  to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}
@keyframes animLoadedLoader {
  to { opacity: 0; -webkit-transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1); }
}
/* Header animation when loading finishes */
.loaded .ip-header {
  -webkit-animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
  animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
}
@-webkit-keyframes animLoadedHeader {
  to { -webkit-transform: translate3d(0,-100%,0); }
}
@keyframes animLoadedHeader {
  to { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
}
/* No JS */
.no-js .ip-header {
  position: relative;
  min-height: 0px;
}
.no-js .ip-header .ip-logo {
  margin-top: 20px;
  height: 180px;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
.no-js .ip-header .ip-logo svg path {
  fill: #fff;
}
.sticky-navigation {
  min-height: 60px;
  background: #FFFFFF;
  opacity: 1;
  padding: 5px 0px !important;
  margin-bottom: 0 !important;
  -webkit-backface-visibility: hidden;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.08);
          box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.08);
}
.sticky-navigation .navbar-header img {
  max-height: 48px;
  -webkit-backface-visibility: hidden;
}
.sticky-navigation .main-navigation .current a {
  position: relative;
  outline: none;
}
.sticky-navigation .main-navigation li a {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
}
.sticky-navigation .main-navigation li a:hover{
  background: none;
}
.sticky-navigation .main-navigation li a:active{
  background: none;
}
.sticky-navigation .main-navigation li a:focus{
  background: none;
}
.navbar-brand{
  padding: 1px;
}
.navbar-brand .logo-title{
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 3px;
}
.navbar-header a:hover{
  color: rgba(0,0,0,0.5);
}
.navbar-header a:focus{
  color: rgba(0,0,0,0.5);
}
.navbar-header a:active{
  color: rgba(0,0,0,0.5);
}
header .only-logo .navbar {
    background: none;
    padding: 60px;
}
header .only-logo .navbar .navbar-header {
    float: none;
}
.intro-section {
  margin: 30vh auto;
  z-index: 1;
}
.intro-section-pad {
  padding-top: 30vh;
}
/*---------------------------------------
   HOME SECTIONS               
-----------------------------------------*/

.home-section {
  height: 100vh; 
  -webkit-background-size: cover;
            background-size: cover;
}

/* =-=-=-=-=-=-= Animated Text =-=-=-=-=-=-= */

.animate-letter-overlay{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0, 0, 0, .5);
    -webkit-backface-visibility: hidden;
}
.animate-letter-wrap {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 100vh;
}
/* =-=-=-=-=-=-= Shattering Text =-=-=-=-=-=-= */
.svg-letter{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.svg-shatter{
  padding: 5vw;
  width: 90vw;
}
.svg-shatter > * {
  fill: #1C1C1C;
  stroke: #1C1C1C;
  stroke-width: 0.85px;
}

@-moz-keyframes tip {
  0%, 100% {
    opacity: 0;
  }
  10%, 80% {
    opacity: 1;
  }
}
@-webkit-keyframes tip {
  0%, 100% {
    opacity: 0;
  }
  10%, 80% {
    opacity: 1;
  }
}
@keyframes tip {
  0%, 100% {
    opacity: 0;
  }
  10%, 80% {
    opacity: 1;
  }
}
/* =-=-=-=-=-=-= Blur Effects =-=-=-=-=-=-= */
.normal-image {
  background-image: url('../images/background/blur-bg-1.jpg'); 
}
.blurImg {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: -1000;
  top: 0;
  left: 0;
}
.blurImg > div {
  position: fixed;
  z-index: -1000;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.blur {
  background-image: url('../images/background/blur-bg-2.jpg');
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}


/* =-=-=-=-=-=-= Aquarium BG =-=-=-=-=-=-= */
.intro-section-2 {
  z-index: 10;
  position:absolute; 
  top:30%; 
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.aquarium{ position: relative;}


/* =-=-=-=-=-=-= Moving BG =-=-=-=-=-=-= */
.moving-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 1;
  font-family: Roboto, sans-serif;
  
  overflow: hidden;
  
}
.moving-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*height: auto;*/
  z-index: -1;
  transform: scale(1.1);
}
.content {
  text-align: center;
  color: #CDD4DE;
}
.ctop{
  padding-top: 150px;
}
.text-white-3 {
  font-family: 'Poiret One', cursive;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 90px;
  line-height: 1;
 /* margin-bottom: 10px;*/
  opacity: 0.9;
}
.content h2 {
  font-family: 'Poiret One', cursive;
  text-transform: none;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 6px;
  opacity: 0.9;
}

.moving-bg-overlay{
   position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0, 0, 0, .3);
    -webkit-backface-visibility: hidden;
}

/* =-=-=-=-=-=-= Four Box- BG =-=-=-=-=-=-= */


.up-title{
  letter-spacing: .5em;
  color: #ffffff;
  font-size: 24px;
  text-transform: none;
  font-family: 'Raleway', sans-serif;
}

.text-black-1{
  font-family: 'Poiret One', cursive;
  font-weight: 100;
  font-size: 100px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: .1em;
  padding-bottom: 10px;
  
}

.fourbox-overlay{
   position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0, 0, 0, .4);
    -webkit-backface-visibility: hidden;
}

.four-box-wrap {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 100vh;
}

.fourbox-overlay-2{
   position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(255, 255, 255, .1);
    -webkit-backface-visibility: hidden;
}

.up-title-2{
  letter-spacing: .5em;
  color: #004dff;
  font-size: 24px;
  text-transform: none;
  font-family: 'Raleway', sans-serif;
}

.text-blue-1{
  font-family: 'Poiret One', cursive;
  font-weight: 100;
  font-size: 100px;
  color: #004dff;
  text-transform: uppercase;
  letter-spacing: .1em;
  padding-bottom: 10px;
  
}

/* =-=-=-=-=-=-= PRISM BG =-=-=-=-=-=-= */

.up-title-3{
  letter-spacing: .5em;
  color: #ffffff;
  font-size: 26px;
  text-transform: none;
  font-family: 'Cinzel Decorative', cursive;
}

.text-white-1{
  font-family: 'Cinzel Decorative', cursive;
  font-weight: 100;
  font-size: 100px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: .1em;
  padding-bottom: 10px;
  
}

/* =-=-=-=-=-=-= HEXAGON BG =-=-=-=-=-=-= */
.text-ash {
  font-family: 'Orbitron', sans-serif;
  text-transform: none;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 90px;
  line-height: 1;
  
  
  color: #ffffff;
  margin-bottom: 20px;
}




/* =-=-=-=-=-=-= YOU TUBE BG =-=-=-=-=-=-= */
.youtube-overlay{
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(255, 255, 255, 0.1);
    -webkit-backface-visibility: hidden;
}
.text-blue{
  color: #004dff;
  font-size: 100px;
  letter-spacing: .1em;
  font-family: "Raleway", sans-serif;
  font-weight: 100;
  margin-left: 50px;
  padding-bottom: 20px;
}
.text-blue-sub{
  color: #0099ff;
  margin-left: 50px;
}



/* =-=-=-=-=-=-= KENBURN BG=-=-=-=-=-=-= */
.kenburn-overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0,0,0,0.3);
    -webkit-backface-visibility: hidden;
}
.text-white-5{
  font-family: 'Cinzel Decorative', cursive;
  color: #B22222;
  text-transform:uppercase;
  text-shadow: 1px 1px #000;
  font-size: 90px;
  font-weight: 400;
  letter-spacing: .1em;
  margin-bottom: 30px;

}
.kenburn-sub-intro{
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  padding: 10px;
}


/* ==================================================================
 SERVICE SECTION
================================================================== */
.serv-icon{
      display: inline-block;
  margin: auto;
  vertical-align: middle;
  opacity: 0.7;
    font-size: 50px;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}
.services {
    margin: 0 auto;
}




.serv {
  border-bottom: 1px solid #eeeef0;
}
.a-service {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-bottom: none;
}
.bg-serv {
  position: relative;
  overflow: hidden;
  background-size: cover;
  padding: 0;
}
.services {
  width: 100%;
  height: 400px;
  left: 0;
  top: 0;
  background: rgba(53, 55, 62, 0.85);
}
@media screen and (max-width: 992px) {
  .services {
    min-height: 100%;
    /*padding: 100px 15px;*/
  }
}
@media screen and (max-width: 768px) {
  .services {
    
    padding-left: 0px;
    padding-right: 0px;
  }
}
.serv-description {
  text-align: right;
  padding: 50px;
  min-width: 350px;
  display: none;
  position: absolute;
}
@media screen and (max-width: 992px) {
  .serv-description {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translate(0);
        -ms-transform: translate(0);
            transform: translate(0);
    max-width: 380px;
    margin: 0 auto;
    min-width: 100%;
  }
}
.this-service {
  display: block;
}
.topic {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  line-height: 1.1;
  text-transform: uppercase;
  color: #fff;
  /*margin-top: 20px;*/
}
.describe {
  float: right;
  font-size: 15px;
  line-height: 28px;
  color: #bbbcc0;
}
.serv-item {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (max-width: 992px) {
  .serv-item {
    padding: 20px 15px;
  }
}
.service {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .service {
    margin-top: 50px;
  }
}

.service h6 {
  text-transform: uppercase;
  font-family: 'Raleway';
  color: #acafba;
  /*margin-top: 33px;*/
  font-size: 13px;
}
.active-item h6 {
  color: #35373e;
}

.active-item .service-icon{
  opacity: 1;
}
.service-icon{
  font-size: 50px;
}
.service:hover h6 {
  color: #35373e;
}
.service:hover img {
  opacity: 1;
}
.clear {
  clear: both;
  overflow: hidden;
  height: 0px;
  font-size: 0px;
  display: block;
}


/* ==================================================================
  PORTFOLIO SECTION
================================================================== */
.portfolio{
  background: #ffffff;
}
.isotope {
  max-width: 100%;
}
/* clear fix */
.isotope:after {
  content: '';
  display: block;
  clear: both;
}
/* ---- .item ---- */
.item {
  float: left;
  width: 100%;
  height: auto;
}
.item.width2 { width: 200%; }
.item.height2 { height: auto; }
* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
#portfolio .container{
  width: 100%;
}
/* ----GRID---- */
 .portfolio-button-group{
    padding-bottom: 45px;
  }
/* ========== Portfolio Filter ========== */
.pf-filter {
  margin-bottom: 40px;
  text-align: center;
  overflow: hidden;
  cursor: default;
}
.pf-filter-page {
  margin-top: -20px;
}
.pf-filter li {
  font-family: 'Raleway', sans-serif;
  display: inline-block;
  height: 34px;
  margin: 0 15px 10px;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
  line-height: 34px;
  text-transform: uppercase;
  color: #000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: border-color 160ms linear;
  -moz-transition: border-color 160ms linear;
  -o-transition: border-color 160ms linear;
  transition: border-color 160ms linear;
}
/*.pf-filter li.active{
  color: #9E4147;
}*/
@media screen and (-webkit-min-device-pixel-ratio: 0){
.pf-filter li {
  line-height: 36px;
}}
.pf-filter li:hover {
  cursor: pointer;
}
.active-filter .line-top,
.active-filter .line-bottom{
  width: 100%;
}
.active-filter .line-left,
.active-filter .line-right{
  height: 100%;
}
/* ========== Button - Lines ========== */
.btn-lines {
  position: relative;
}
.btn-lines .line-top,
.btn-lines .line-bottom,
.btn-lines .line-left,
.btn-lines .line-right {
  position: absolute;
  background: #666;
  -webkit-transition: width 210ms ease-out 10ms, height 210ms ease-out 10ms;
  -moz-transition: width 210ms ease-out 10ms, height 210ms ease-out 10ms;
  -o-transition: width 210ms ease-out 10ms, height 210ms ease-out 10ms;
  transition: width 220ms ease-out 10ms, height 220ms ease-out 10ms;
}
.btn-lines .line-top,
.btn-lines .line-bottom {
  width: 0;
  height: 1px;
}
.btn-lines .line-top {
  top: 0;
  left: 0;
}
.btn-lines .line-bottom {
  bottom: 0;
  right: 0;
}
.btn-lines .line-left,
.btn-lines .line-right {
  width: 1px;
  height: 0;
}
.btn-lines .line-left {
  bottom: 0;
  left: 0;
}
.btn-lines .line-right {
  top: 0;
  right: 0;
}
.btn-lines:hover .line-top,
.btn-lines:hover .line-bottom {
  width: 100%;
}
.btn-lines:hover .line-left,
.btn-lines:hover .line-right {
  height: 100%;
}
/* EVEN GRID */
.grid {
  margin: 0px;
  max-width: 100%;
  text-align: center;
}
.grid:after {
  content: '';
  display: block;
  clear: both;
}
.grid-item { 
  width: 100%;
  height: auto; 
  background: #FFFFFF;
}
/*3 COLUMN MASSONRY*/
.grid-masonry{
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
}
.m-grid-item {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  padding: 5px;
}
.m-grid-item--height2 { height: 100%; }
/* 4 COLUMN MASSONRY*/
.m-grid-item-2 {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  padding: 5px;
}
.m-grid-item-2--height2 { height: 100%; }
/*PORTFOLIO HOVER EFFECT*/
.portfolio-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.portfolio-hover .action-btn {
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 35px;
}
.portfolio-hover .action-btn a {
  color: #000;
}
.grids-item:hover .portfolio-hover,
.grids-item:hover .portfolio-hover .portfolio-description,
.grids-item:hover .portfolio-hover .action-btn {
    opacity: 1;
}
.grids-item:hover .portfolio-hover .portfolio-description {
    bottom: 45%;
    margin-bottom: -90px;
}
.grids-item:hover .portfolio-hover .action-btn {
    top: 55%;
    margin-top: -55px;
}
/* ==================================================================
  PORTFOLIO SINGLE PROJECT PAGE 
================================================================== */

.breadcrumb {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: transparent;
    border-radius: 4px;
    letter-spacing: 0.2em;
}

.breadcrumb li {
    text-transform: uppercase;
}

.breadcrumb li > a  {
    color: #000;
}

.breadcrumb li > a:hover {
    color: #222;
}

.breadcrumb>.active {
    color: #222;
}


.video-wrap {
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 100vh;
}

.video-overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 5;
    background: rgba(0,0,0,0.2);
    -webkit-backface-visibility: hidden;
}

.portfolio-back-link{
  padding-top: 8%;
}
.portfolio-image-parallax{
  width: 100%;
  height: 250px;
  padding-bottom: 20px;
}
.portfolio-project{
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 100px;
}
.portfolio-project-heading{
  font-family: 'Raleway' sans-serif;
  text-transform: uppercase;
  text-align: left;
  padding-top: 90px;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ddd;
}
.portfolio-project-title {
  padding-top: 20px;
  font-family: 'Raleway' sans-serif;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
}
.portfolio-project-details{
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.8;
}
.portfolio-project-info {
    list-style: none;
    padding-top: 80px;
    text-align: center;
}
.portfolio-project-info li {
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 15px 0;
    color: #000;
    
}
.portfolio-project-info li span {
    color: #6d747c;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: .2em;
}
.portfolio-project-info li a {
    font-size: 32px;
    color: #000;
}
.portfolio-project-info li a i{
  vertical-align: middle;
}
.portfolio-project-info li a:hover {
}

#content-slider .item img{
    display: block;
    width: 100%;
    height: auto;
}
.portfolio-project-media .owl-carousel .owl-controls .owl-page span {
  margin-top: 30px; 
}
.project-desc .pull-top{
  position: relative;
  top: -30px;
}
/* SIMILAR WORK WITH CAROSEL */
.similar-project{
  padding-top: 20px;
  padding-bottom: 120px;
}
.similar-project-title{
  font-family: 'Raleway' sans-serif;
  padding-bottom: 50px;
  text-align: left;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 30px 0 0 0;
}
.similar-project-grid-item {
  width: 300px;
  height: 250px;
  background: #FFFFFF;
  padding: 15px;
}
.card {
  background-color: #fff;
}
.card__content {
  position: relative;
}
.card__padding {
  padding: 1.2rem;
}
.border-tlr-radius { 
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; 
}
.project-image{
  width: 100%;
  height: auto;
   padding-left: 0px;
  padding-right: 0px;
   }
/* ==================================================================
  ABOUT US
================================================================== */


/* About Us Hover Logo Effect */

.link--yaku {
  color: #D3D3D3;
  font-family: 'Playfair Display';
  font-weight: 400;
  text-transform: uppercase;
  font-size: 5em;
  overflow: hidden;
  padding: 0 0 10px;
  margin-top: 20px;
}
.link--yaku::before {
  top: -10px;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-width: 2px 0;
  border-color: #282828;
  border-style: solid;
  left: 0;
  -webkit-transform: translate3d(-101%,0,0);
  transform: translate3d(-101%,0,0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}
.link--yaku:hover::before {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}
.link--yaku span {
  display: inline-block;
  position: relative;
  -webkit-transform: perspective(1000px) rotate3d(0,1,0,0deg);
  transform: perspective(1000px) rotate3d(0,1,0,0deg);
  -webkit-transition: -webkit-transform 0.5s, color 0.5s;
  transition: transform 0.5s, color 0.5s;
}
.link--yaku:hover span {
  color: #1e1a1b;
  -webkit-transform: perspective(1000px) rotate3d(0,1,0,360deg);
  transform: perspective(1000px) rotate3d(0,1,0,360deg);
}
.link--yaku span:nth-child(4),
.link--yaku:hover span:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.link--yaku span:nth-child(3),
.link--yaku:hover span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.link--yaku span:nth-child(2),
.link--yaku:hover span:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.link--yaku span:first-child,
.link--yaku:hover span:nth-child(4) {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
/* Media Queries */
@media screen and (max-width: 42em) {
  .link--yaku { font-size: 4em; }
}


/*About Us Description*/

.about{
  position: relative;
  z-index: 1000;
  background: #FFFFFF;
}
.about-carousel {
  text-align: left;
}
.about-carousel .about-title{
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  padding-left: 20px;
}
.about-carousel .about-subtitle{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 20px; 
}
.about-carousel .about-port{
 font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: #111;
}
.about-carousel .about-desc{
  padding-left: 20px;
}
/* the Mark */
.mark {
  margin: 20px 0;
  width: 45px;
  border: 2px solid #111;
}
.mark-light{
  margin: 20px 0;
  width: 45px;
  border: 2px solid #fff;
}
.about-carousel .owl-theme .owl-controls .owl-page span {
    background: #111;
    border-radius: 20%;
    max-height: 3px;
}


/* ==================================================================
  TESTIMONIAL
================================================================== */
.testimonial{
  background: #ffffff;
}
.center-col{ float:none !important; margin-left:auto !important; margin-right:auto !important}
.testimonial .container {
  width: 100%;
}
.testimonials {
    background: url(../images/bg-3.jpg) no-repeat center fixed;
    -webkit-background-size: cover;
            background-size: cover;
    color: #ffffff;
}
.testimonials .color-overlay {
    background: rgba(0, 0, 0, .6);
    padding-top: 40px;
    padding-bottom: 40px;
}
.testimonials .feedback {
    max-width: 750px;
    margin: 0 auto;
}
.testimonials .feedback .image {
    font-size: 24px;
    border: 2px solid transparent;
    -webkit-box-shadow: 0px 0px 0px 2px #ffffff;
            box-shadow: 0px 0px 0px 2px #ffffff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: auto;
    margin-top: 5px;
    overflow: hidden;
}
.testimonials .feedback .image img {
    width: 100%;
    vertical-align: top;
}
.testimonials .feedback .message {
    font-style: normal;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.testimonials .feedback .name {
    margin-top: 15px;
    font-weight: 400;
    text-align: center;
}
.testimonials .feedback .company-info {
    font-size: 12px;
    text-align: center;
}
.testimonials .owl-theme .owl-controls .owl-page span {
    border-radius: 20%;
    max-height: 2px;
}
.testimonials .customNavigation a {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
            user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* ==================================================================
 CLIENT
================================================================== */
.clients{
  background: #ffffff;
}
#clients-1{
  padding-top: 10px;
}
.clients .owl-theme .owl-controls .owl-page span {
    border-radius: 20%;
    max-height: 2px;
}
/* ==================================================================
 TEAM
================================================================== */
.team{
    padding-bottom: 60px;
    background: #ffffff;
}
.grid {
  padding: 20px 20px 30px 20px;
  max-width: 1300px;
  margin: 0 auto;
  list-style: none;
  text-align: center;
}
.grid li {
  display: inline-block;
  width: 440px;
  margin: 0;
  padding: 20px;
  text-align: left;
  position: relative;
}
.grid figure {
  margin: 0;
  position: relative;
}
.grid figure img {
  max-width: 100%;
  display: block;
  position: relative;
}
.grid figcaption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  color: #ed4e6e;
}
.grid figcaption h3 {
  font-family: "Raleway",sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 10px;
  color: #fff;
}
.grid figcaption span {
   font-family: "Raleway",sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 80px;

}

.grid figcaption {
  background: #1d1d1d;
 }

.grid figcaption a {
  /*opacity: 0.1;*/
  font-size: 24px;
  text-align: center;
  margin: 5px;
  padding: 5px 8px;
  border-radius: 5px;
  display: inline-block;
  background: #ed4e6e;
  color: #fff;
}
/* Caption Style 4 */
.cs-style-4 li {
  -webkit-perspective: 1700px;
  -moz-perspective: 1700px;
  perspective: 1700px;
  -webkit-perspective-origin: 0 50%;
  -moz-perspective-origin: 0 50%;
  perspective-origin: 0 50%;
}
.cs-style-4 figure {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.cs-style-4 figure > div {
  overflow: hidden;
}
.cs-style-4 figure img {
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}
.no-touchevents .cs-style-4 figure:hover img,
.cs-style-4 figure.cs-hover img {
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}
.cs-style-4 figcaption {
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
  transition: transform 0.4s, opacity 0.1s 0.3s;
}
.no-touchevents .cs-style-4 figure:hover figcaption,
.cs-style-4 figure.cs-hover figcaption {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.1s;
  -moz-transition: -moz-transform 0.4s, opacity 0.1s;
  transition: transform 0.4s, opacity 0.1s;
}

/* ==================================================================
  PACKAGES
================================================================== */
.packages {
    background: #ffffff;
    
}
.packages .container{
  width: 100%;
}
.package-heading{
  margin-top: 40px;
  font-family: 'Raleway', sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}
.single-package {
    width: 100%;
    background: transparent;
    overflow: auto;
    border: 1px solid #9b9b9b;
    border-radius: 0px;
    margin-bottom: 60px;
    color: #000;
}
.package-padding{
  padding-left: 40px;
  padding-right: 40px;
}
.single-package .solid-bg{
  background: #fff;
}
.single-package .price {
    height: 120px;
  
}
.single-package .price h3 {
    padding-top: 10px;
    color: #000;
    font-size: 45px;
    font-weight: 500;
    margin: 0px;
    font-family: "Advent pro",sans-serif;
}



.single-package .price h4{
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  margin-bottom: 0px;
}
.single-package .price .sign {
    vertical-align: super;
    font-size: 20px;
    padding-right: 5px;
}
.single-package .package-feature {
  /*margin-top: 15px;*/
  margin-bottom: 15px;
 
}
.single-package .package-feature > li {
  margin-top: 7px;
  margin-bottom: 7px;
  text-transform: uppercase;
  border-top: 2px dotted rgba(125,125,125,0.4);
  font-size: 12px;
  font-weight: 500;
}
/* Button hover effect */
.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #111;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right:hover, .hvr-bounce-to-right:focus, .hvr-bounce-to-right:active {
  color: white;
}
.hvr-bounce-to-right:hover:before, .hvr-bounce-to-right:focus:before, .hvr-bounce-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
/* version 2 */
.hvr-bounce-to-right-white {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-to-right-white:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-bounce-to-right-white:hover, .hvr-bounce-to-right-white:focus, .hvr-bounce-to-right-white:active {
}
.hvr-bounce-to-right-white:hover:before, .hvr-bounce-to-right-white:focus:before, .hvr-bounce-to-right-white:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}
/* ==================================================================
  FUN FACTORS
================================================================== */
.funfactor {
    background: url('../images/bg-4.jpg') no-repeat center fixed;
    -webkit-background-size: cover;
            background-size: cover;
    color: #ffffff;
}
.funfactor .color-overlay {
    background: rgba(0, 0, 0, .7);
    padding-top: 80px;
    padding-bottom: 80px;
}
.funfactor .fun-info {
  padding-bottom: 20px;
}
.funfactor .icon-container {
    display: block;
    margin-top: 5px;
}
.funfactor .icon-container i {
    font-size: 30px;
    color: #279aae;
    text-align: center;
    -webkit-transition: all ease-in 0.25s;
            transition: all ease-in 0.25s;
    -webkit-background-clip: padding-box;
           -moz-background-clip: padding;
            background-clip: padding-box;
}
.funfactor h2 {
    line-height: 2rem;
}
.fun-work{
    font-size: 16px;
    letter-spacing: 1px;
}
/* ==================================================================
  BLOG SECTIONS
================================================================== */
 
 .blog{
  background: #ffffff;
 }
/*Blog image hover effect*/
.figure {
    position: relative;
}
.figure i{
    position: absolute;
    bottom: -8px;
    right: 30px;
    color: #fff;
    font-size: 18px;
    z-index: 100;
}
.figure i:after {
    position: absolute;
    content: "";
    left: -12px;
    top: -10px;
    width: 40px;
    height: 40px;
    background-color: #68c3a3;
    z-index: -1;
    border-radius: 3px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}
.post-thumbnail {
    margin-bottom: 20px;
    overflow: hidden;
}
.post-thumbnail img{
    width: 100%;
    opacity: 0.8;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
.post-thumbnail img:hover {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
}
 /*Blog info*/
 .blog-title{
   font-family: 'Raleway', sans-serif;
   text-transform: uppercase;
   font-weight: 600;
   font-size: 18px;
   letter-spacing: 1px;
   color: #000;
 }
 .blog-sub-title{
   font-family: 'Raleway', sans-serif;
   text-transform: uppercase;
   font-weight: 400;
   font-size: 11px;
   padding-top: 5px;
   letter-spacing: 1.5px;
   color: #1e1e1e;
 }
 .blog-post{
  color: #949494;
 }
 .author{
  color: #000;
  font-size: 18px;
  padding-right: 10px;
 }
 .date{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
   font-size: 11px;
   padding-bottom: 5px; 
 }
.black-line {
    text-align: left;
    margin: auto;
    z-index: 1;
    width: 65px;
    height: 1px;
    background: #000;
}
.black-line-2 {
    text-align: center;
    margin: auto;
    z-index: 1;
    width: 55px;
    height: 1px;
    background: #000;
}
 .read-more{
  color: #949494;
 }
 .read-more :hover {
  color: #279aae;
 }
/* ==================================================================
  BLOG LIST PAGE
================================================================== */


/* ==================================================================
 CONTACT
================================================================== */
.contact{
  background: #ffffff;
}
.contact  .contact-box {
    padding-bottom: 20px;
}
.contact  .contact-address{
    /*text-align: left;*/
    margin-top: 10px;
    margin-bottom: 30px;
}
.contact  .contact-address i{
  font-size: 22px;
  vertical-align: middle;
  margin-right: 6px;
}
.contact  .contact-address ul li{
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height:18px;
  padding: 10px;
}
.contact  .contact-box .contact-button {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin: auto;
    position: relative;
    font-size: 30px;
    line-height: 60px;
    vertical-align: middle;
    margin-top: -40px;
    background: #232323;
    -webkit-box-shadow: 0px 0px 0px 3px #232323;
            box-shadow: 0px 0px 0px 3px #232323;
    -webkit-backface-visibility: hidden;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}
.contact  .contact-box .contact-button:hover {
    color: #ffffff;
}
.contact  .contact-box .contact-form {
    margin-top: 10px;
    
}
.contact  .contact-box .contact-form .success,
.contact  .contact-box .contact-form .error {
    display: none;
}
.contact  .contact-box .contact-form .input-box {
    margin-bottom: 10px;
    margin-top: 10px;
    height: 40px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: transparent;
    border: 2px solid #111;
}
.contact  .contact-box .contact-form .textarea-box {
    margin-top: 20px;
    margin-bottom: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: transparent;
    height: 120px;
    border: 2px solid #111;
}
/* ==================================================================
GOOGLE MAP
================================================================== */
#map {
    width: 100%;
    height: 400px;
}
/* ==================================================================
 FOOTER
================================================================== */
.footer-section {
  background: #1d1d1d;
  text-align: left;
}
.footer-logo {
  width: 100px;
  margin: 10px auto;
}
.footer-logo p {
  color: #FFFFFF;
  font-size: 24px;
  /*text-align: center;*/
  letter-spacing: .7em;
  padding-left: 16px;
  padding-top: 54px;
}
.input-group{
  margin: 30px auto;
  width: 90%;
  font-family: 'Source Sans Pro', sans-serif;
  color: #949494;
}
.subscriber{
  border: 2px solid #fff;
}
.subscriber:focus{
  color: #949494;
}
#widget-subscribe-submit-button{
  position: relative;
  margin: 0px auto;
}
.modal-backdrop.in {
  opacity: .75;
}
.modal-content {
  box-shadow: none;
  border-radius: 0;
}
.modal-header {
  position: relative;
}
.modal-header .close {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  height: 20px;
  font-size: 15px;
}
.form-control{
  height: 100%;
}
.footer-content{
    /*text-align: center;*/
    color: #949494;
}
.footer-content .footer-column{
  padding: 0px auto;
}
.social-icons {
  padding:0px;
  margin: 0 4.2em;
  text-align: left;
}
.social-icons li {
    display: inline-block;
    margin: 5px;
}
.social-icons li a {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 37px;
  font-size: 23px;
  color: #949494;
}
.copyright p{
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: #949494;
    padding-top: 10px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: 5em;
  max-width: 350px;
  width: calc(100% - 2em);
  vertical-align: top;
}
.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}
.input__field:focus {
  outline: none;
}
.input__label {
  font-family: 'Raleway', sans-serif;
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #aaa;
  font-weight: 300;
  font-size: 80%;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input__label-content {
  position: relative;
  display: block;
  padding: .8em 0;
  width: 100%;
}
.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}
.icon {
  color: #ddd;
  font-size: 150%;
}
/* Hoshi */
.input--hoshi {
  overflow: hidden;
}
.input__field--hoshi {
  margin-top: 1em;
  padding: 0.85em 0.15em;
  width: 100%;
  background: transparent;
  color: #595F6E;
}
.input__label--hoshi {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.25em;
  width: 100%;
  height: calc(100% - 1em);
  text-align: left;
  pointer-events: none;
}
.input__label-content--hoshi {
  position: absolute;
}
.input__label--hoshi::before,
.input__label--hoshi::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  border-bottom: 1px solid #B9C1CA;
}
.input__label--hoshi::after {
  margin-top: 2px;
  border-bottom: 3px solid red;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.input__label--hoshi-color-2::after {
  border-color: hsl(160, 100%, 50%);
}
.input__field--hoshi:focus + .input__label--hoshi::after,
.input--filled .input__label--hoshi::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.input__field--hoshi:focus + .input__label--hoshi .input__label-content--hoshi,
.input--filled .input__label-content--hoshi {
  -webkit-animation: anim-1 0.3s forwards;
  animation: anim-1 0.3s forwards;
}
@-webkit-keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}
@keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}



/* ==================================================================
 SCROLL TO TOP
================================================================== */
.scroll-up {
  position: relative;
  top: -30px;
  display: table;
  z-index: 999;
  margin: 0 auto;
}
.scroll-up a {
  display: block;
  width: 50px;
  height: 30px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.scroll-up a:hover,
.scroll-up a:active {
  background-color: rgba(235, 235, 235, .8);
  color: #222;
}


/* ==================================================================
  BLOG SECTIONS
================================================================== */
 
.entry-content {
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #FFFFFF;
  position:relative;
  height: 290px;
}
.news-blog{
 margin-bottom:10px;
}
.post-single .post-img {
    position: relative;
}
.post-single .post-img{
    width: 100%;
    /*height: 309px;*/
}
.post-single .post-img img{
    width: 100%;
    height: 100%;
}
.post-single .post-img.top:after {
    top: -7px;
}
.post-single .post-img.bottom:after{
    bottom: -7px;
}
.post-single .post-img.top:after,
.post-single .post-img.bottom:after {
    background-color: #FFFFFF;
    border-color: #fff;
    border-image: none;
    border-right: 1px solid #fff;
    border-style: none;
    content: "";
    display: block;
    height: 14px;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 15px;
}
.entry-content  a h4 {
  color:#454545;
  line-height:32px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 480px) {
  .post-thumbnail {
    margin-bottom: 0px;
  }
}


/* ==================================================================
Easy Pie Chart
================================================================== */

.about-skills{
  text-align: center;
  background: #ffffff;
}
.about-skills canvas {
    margin: 0 auto;
}
.percent-skill{
  position: absolute;
  text-align: center;
  font-family: 'Advent Pro', sans-serif;
  font-weight: 600;
  font-size: 25px;
  left: 43%;
  bottom: 55%;
}
.pie-chart {
  margin: 0 auto;
  font-size: 50px;
  font-weight: 500;
}
.pie-chart i {
  font-size: 80px;
  line-height: 200px;
}
.pie-chart-text {
  font-size: 12px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
}
/* ==================================================================
Work Process
================================================================== */
.workprocess{
  background: #ffffff;
}

.work-section-title{
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  padding-top: 30px;
}
 .work-section-title p {
  padding-top: 10px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
 }

#work-process .divider {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  #work-process .divider {
    margin-right: auto;
    margin-left: auto;
  }
}

.work-process {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .work-process {
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0px 15px;
  }
}

.work-process .itemz {
  position: relative;
  padding: 0;
}

@media (max-width: 767px) {
  .work-process .itemz {
    margin: 0 auto;
    width: 200px;
    height: 132px;
  }
}

@media (max-width: 767px) {
.work-process .itemz:before {
    position: absolute;
    z-index: 10;
    content: '';
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    margin-bottom: -66px;
    border-left: 1px dashed #6e6e6e;
  }
}

.work-process .itemz .inner-content {
  z-index: 5;
  position: relative;
  width: 100%;
  height: 132px;
}

@media (min-width: 768px) {
  .work-process .itemz .inner-content {
    margin-top: 102px;
    height: 102px;
  }
}

.work-process .itemz .inner-content:before {
  position: absolute;
  z-index: 10;
  content: '';
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  border-top: 1px solid #9c9b9b;
}

.work-process .itemz .inner-content:after {
  position: absolute;
  z-index: 10;
  content: '';
  top: 50%;
  right: 0;
  width: 1px;
  height: 100%;
  margin-top: -132px;
  border-left: 1px dashed #9c9b9b;
}

@media (min-width: 768px) {
  .work-process .itemz .inner-content:after {
    margin-top: -102px;
  }
}

.work-process .itemz .inner-content > i {
  position: absolute;
  z-index: 15;
  top: 50%;
  left: 50%;
  display: block;
  width: 86px;
  height: 66px;
  line-height: 58px;
  margin-top: -33px;
  margin-left: -43px;
  font-size: 30px;
  text-align: center;
  border-radius: 3px;
  border: 4px double #dedede;
}

.work-process .itemz .inner-content > span.title {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  line-height: 66px;
  margin-top: -18px;
  text-align: center;
  text-transform: uppercase;
  font-style: 400;
  font-size: 14px;
}

@media (min-width: 768px) {
  .work-process .itemz .inner-content > span.title {
    margin-top: -33px;
  }
}

.work-process .itemz .inner-content > span.dot {
  position: absolute;
  z-index: 15;
  content: '';
  top: 50%;
  left: 0;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  background-color: #9c9b9b;
  border-radius: 25%;
}

.work-process .itemz > i.icon-Arrow {
  display: block;
  position: absolute;
  z-index: 15;
  top: 45%;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-top: 45px;
  margin-right: -25px;
  font-size: 24px;
  text-align: center;

}

@media (min-width: 768px) {
  .work-process .itemz > i.icon-Arrow.icon-Down-3 {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .work-process .itemz > i.icon-Arrow.icon-Up-3 {
    left: 0;
    margin-left: -25px;
    -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media (min-width: 768px) {
  .work-process .itemz > i.icon-Arrow.icon-Up-3 {
    margin-top: -25px;
  }
}

.work-process .itemz.first .inner-content:before {
  left: auto;
  right: 0;
}

@media (max-width: 767px) {
  .work-process .itemz.last:before {
    display: none;
  }
}

@media (max-width: 767px) {
  .work-process .itemz.last .inner-content:before {
    left: auto;
    right: 0;
  }
}

.work-process .itemz.last .inner-content:after {
  display: none;
}

@media (min-width: 768px) {
  .work-process .itemz.last .inner-content > span.dot {
    right: 0;
    left: auto;
  }
}

@media (max-width: 767px) {
  .work-process .itemz.no-margin:before {
    display: none;
  }
}

.work-process .itemz.no-margin .inner-content {
  margin-top: 0px;
}

.work-process .itemz.no-margin .inner-content:after {
  margin-top: 0px;
}

@media (min-width: 768px) {
  .work-process .itemz.no-margin .inner-content > span.title {
    top: auto;
    bottom: 0;
    margin-bottom: -33px;
  }
}

/*-------------------- 14-BLOCK-TYPE-4 ------------*/
/*New Team*/
/* 11.PM Slider container 
 */
.pm-slider-container {
  /*background-image:url(../img/slider/slide1.jpg);*/
  background-repeat:no-repeat;
  background-position:top center;
  width:100%;
  min-height:700px;
}
.pm-slider {
    height:650px !important;  
  }
.pm-slider ul li {
    background-size:cover !important; 
  }
.pm-holder {
    padding-top:140px !important; 
  }
  .pm-caption{
    text-align: center;
  }
  
  .pm-caption h1 {
   
    font-size:60px;
  }
  
  .pm-caption-decription {
    /*width:320px !important;*/
  }



/* Clients Opacity modifier */
#clients-1 .owl-item{
  opacity: 0.5;
}
#clients-1 .owl-item:hover{
  opacity: 1;
}
/*  
    Responsive styles
    --------------
    min-width: mobile-first,max-width: desktop-first
    Contents
    ========
    min-width: 481px
    max-width: 767px
    min-width: 768px
    min-width: 992px
    min-width: 1200px
    max-width: 480px
*/
@media (max-width: 768px) {
  .moving-img { width: auto; height: 100%; }
  .hs1-h1{
    font-size: 60px;
  }
   .hs2-h1{
    font-size: 60px;
  }
  .hs3-h1{
    font-size: 60px;
  }
  .hs4-h1{
    font-size: 60px;
  }
  .hs5-h1{
    font-size: 60px;
  }
  .hs6-h1{
    font-size: 60px;
  }
  .hs7-h1{
    font-size: 60px;
  }
  .hs8-h1{
    font-size: 60px;
  }
  .hs9-h1{
    font-size: 60px;
  }
  .hs10-h1{
    font-size: 60px;
  }
  .hs11-h1{
    font-size: 60px;
  }
  h2{
    font-size: 28px;
    line-height: 1em;
    padding-bottom: 20px;
  }
  .content-slider-subtitle{
    font-size: 14px;
  }
  .carousel-indicators li{
    margin-bottom: 20%;
  }
  .carousel-indicators .active{
    margin-bottom: 20%;
  }
  .text-black-4{
    padding-bottom: 0px;
  }
  .portfolio-project-heading { text-align: center; }
  .portfolio-project-info { padding-top: 0px;}
  .portfolio-back-link { padding-top: 0%; }
  .percent-skill { left: 47%; }
}

@media (max-width: 480px) {
  .hs1-h1{
    font-size: 30px;
  }
  .hs2-h1{
    font-size: 30px;
  }
   
  .hs3-h1{
    font-size: 30px;
  }
  
  .hs4-h1{
    font-size: 30px;
  }
  .hs5-h1{
    font-size: 30px;
  }
  .hs6-h1{
    font-size: 30px;
  }
  .hs7-h1{
    font-size: 30px;
  }
  .hs8-h1{
    font-size: 30px;
  }
  .hs9-h1{
    font-size: 30px;
  }
  .hs10-h1{
    font-size: 30px;
  }
  .hs11-h1{
    font-size: 30px;
  }
  .inline-date{
    text-align: left;
    display: inline;
    vertical-align: middle;
  }
  .date-num{
    display: none;
  }
  .content-slider-subtitle{
    font-size: 14px;
  }
  .carousel-indicators li{
    margin-bottom: 30%;
  }
  .carousel-indicators .active{
    margin-bottom: 30%;
  }
  .text-black-4{
    padding-bottom: 0px;
  }
  .portfolio-project-heading { text-align: center; }
  .portfolio-project-info { padding-top: 0px;}
  .portfolio-back-link { padding-top: 0%; }
  .percent-skill { left: 45%; }
}
@media (min-width: 480px) {
  .hs1-h1{
    font-size: 60px;
  }
  .hs2-h1{
    font-size: 60px;
  }
  .hs3-h1{
    font-size: 60px;
  }
  .hs4-h1{
    font-size: 60px;
  }
  .hs5-h1{
    font-size: 60px;
  }
  .hs6-h1{
    font-size: 60px;
  }
  .hs7-h1{
    font-size: 60px;
  }
  .hs8-h1{
    font-size: 60px;
  }
  .hs9-h1{
    font-size: 60px;
  }
  .hs10-h1{
    font-size: 60px;
  }
  .hs11-h1{
    font-size: 60px;
  }
  h2{
    font-size: 28px;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .hs1-h1{
    font-size: 90px;
  }
   .hs2-h1{
    font-size: 90px;
  }
  .hs3-h1{
    font-size: 90px;
  }
  .hs4-h1{
    font-size: 90px;
  }
  .hs5-h1{
    font-size: 90px;
  }
  .hs6-h1{
    font-size: 90px;
  }
  .hs7-h1{
    font-size: 90px;
  }
  .hs8-h1{
    font-size: 90px;
  }
  .hs9-h1{
    font-size: 90px;
  }
  .hs10-h1{
    font-size: 90px;
  }
  .hs11-h1{
    font-size: 90px;
  }
  h2{
    font-size: 36px;
  }
  .text-blue{
    margin-top: 300px;
  }
}
@media (min-width: 1200px) {
  .hs1-h1{
    font-size: 100px;
  }
   .hs2-h1{
    font-size: 100px;
  }
  .hs3-h1{
    font-size: 100px;
  }
  .hs4-h1{
    font-size: 90px;
  } 
  .hs5-h1{
    font-size: 100px;
  } 
  .hs6-h1{
    font-size: 80px;
  }
  .hs7-h1{
    font-size: 90px;
  }
  .hs8-h1{
    font-size: 80px;
  }
  .hs9-h1{
    font-size: 100px;
  }
  .hs10-h1{
    font-size: 100px;
  }
  .hs11-h1{
    font-size: 100px;
  }
   h2{
    font-size: 42px;
  }
  
  .text-blue{
    margin-top: 350px;
  }
}
@media (min-width: 1200px) {
  .blog-list-wrap{
    margin: 0px 80px; 
  }
}


